/* colors https://coolors.co/3c91e6-bf0603-e7e247-edf2f4-0a0908 */

.special-p {
	position: absolute;
	left: 0;
	top: 0;
}

.special-b {
	position: absolute;
	left: calc(50vw - 23px);
	top: 300px;

}

@media only screen and (max-width: 1423px) {
	.special-b {
		top: 550px;
	}
}

.view-1 {
	overflow-y: hidden;
	overflow-x: hidden;
	/* min-height: 100vh; */
	/* max-height: 100vh; */
	font-size: calc(10px + 2vmin);
	/* background-color: #0A0908; */
	color: #edf2f4;
}

.name {
	font-size: 22rem;
	line-height: .704;
	font-weight: 700;
	margin: -4px 0 0 -34px;
}

@media only screen and (max-width: 742px) {
	.name {
		font-size: 15rem;
		line-height: .704;
		font-weight: 700;
		margin: -2px 0 0 -20px;
	}
	.special-b {
		top: 1000px;
	}
}

@media only screen and (max-width: 510px) {
	.name {
		font-size: 11rem;
		/* line-height: .704; */
		/* font-weight: 700; */
		/* margin: -2px 0 0 -20px; */
	}
	.special-b {
		top: 500px;
	}
}

.open-link {
	all: unset;
	margin-top: 50px;
	/* position:absolute; */
	/* z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center; */
	color: #3c91e6;
	text-decoration: none;
}

.open-link:hover {
	color: #e7e247;
	text-decoration: none;
}

.open-link:active {
	color: #bf0603;
	text-decoration: none;
}

.view-2 {
	/* overflow-y: hidden;
	overflow-x: hidden; */
	height: 100%;
	font-size: calc(10px + 2vmin);
	background-color: #edf2f4;
	color: #0A0908;
	/* display: flex; */
	/* justify-content: space-between; */
}

.sidebar {
	margin-left: 1rem;
	font-size: .75rem;
	position: fixed;
	z-index: 100;
	left: 0px;
	/* width: var(--panel-width); */
	height: 100%;
	overflow: auto;
	/* border-right: var(--border-style); */
	display: flex;
	flex-direction: column;
	transition: 0s 0s height;
}

hr.thin {
	height: 1px;
	border: 0;
	color: #333;
	background-color: #333;
	width: 80%;
	margin: 0px 0px 0px 0px;
}

#header {
	display: flex;
	/* height: var(--header-height); */
	/* border-bottom: var(--border-style); */
	margin-top: 15px;
	align-items: center;
}

h1 {
	line-height: 24px;
	font-weight: 500;
	margin-bottom: 0;
}

.first-h2 {
	all: unset;
	margin-top: 10px !important;
	border-top: none;
	padding-top: 0;
}

.h2-size {
	all: unset;
	font-size: 1.5rem;
	margin-bottom: 2px;
	padding-bottom: 0px;
	/* margin-top: 18px; */
	border-top: none;
	padding-top: 6px;
	padding: 0;
	/* margin: 16px 0; */
	/* font-size: calc(var(--font-size) - 1px); */
	/* line-height: var(--line-height); */
	font-weight: 500;
	user-select: none;
}

/* .ul-container {
	transition: 0.4s;
} */

ol, ul {
	padding-left: .5rem !important;
}

ul {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0 !important;
	/* transition: max-height 0.2s ease-out; */
	/* overflow: hidden; */
	/* max-height: 0; */
}

li {
	margin: 1px 0;
	display: list-item;
	text-align: -webkit-match-parent;
}

.flat-link {
	position: relative;
	text-decoration: none;
}

.back-link {}

.grid {
	margin-left: 175px;
	margin-top: 25px;
}

.img-wrap {
	max-width: 200px !important;
	max-height: 200px !important;
	overflow: hidden;
	padding: 0px !important;
}

.img-t {
	max-width: initial;
	max-height: initial;
}

.iframe-google-form {
	/* overflow-y: auto; */
	/* overflow-y: hidden; */
}

.modal-body {
	display: flex;
	justify-content: center;
}

.my-masonry-grid {
	display: -webkit-box;
	/* Not needed if autoprefixing */
	display: -ms-flexbox;
	/* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px;
	/* gutter size offset */
	width: auto;
}

.my-masonry-grid_column {
	padding-left: 30px;
	/* gutter size */
	background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}

.color-74A57F {
	color: #74A57F;
}

.color-D30C7B {
	color: #D30C7B;
}

.color-57E2E5 {
	color: #57E2E5;
}

.color-A50104 {
	color: #A50104;
}

.color-EBF8B8 {
	color: #EBF8B8;
}

.color-775144 {
	color: #775144;
}

.color-7FB069 {
	color: #7FB069;
}

.color-EFCA08 {
	color: #EFCA08;
}

.color-A14DA0 {
	color: #A14DA0;
}

.color-D11149 {
	color: #D11149;
}

.color-A9FFF7 {
	color: #A9FFF7;
}

.color-97CC04 {
	color: #97CC04;
}

.color-31E981 {
	color: #31E981;
}

.color-F0C808 {
	color: #F0C808;
}

.color-2EC4B6 {
	color: #2EC4B6;
}

.color-6320EE {
	color: #6320EE;
}

.color-00D9C0 {
	color: #00D9C0;
}
* {
  box-sizing: border-box;
}

html, body, #root, .container-fluid {
  height: 100vh;
  overflow: hidden;
}

.montserrat{
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
 
  /* colors https://coolors.co/3c91e6-bf0603-e7e247-edf2f4-0a0908 */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

canvas {
  height: 100vh;
}